import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

interface Project {
  id: number;
  title: string;
  description: string;
  videoUrl?: string;
  link?: string;
  coverImage?: string;
}

const projects: Project[] = [
  {
    id: 1,
    title: "RabbitHole",
    description: "An interactive journey learning platform leveraging AI for personalized education experiences. (Jun 2024)",
    videoUrl: "https://www.youtube.com/embed/7pN9308VnNw",
  },
  {
    id: 2,
    title: "Political Bias Analyzer",
    description: "Paste any news URL to get a bias report, score, and see the article reframed from different perspectives. (Jul 2024)",
    videoUrl: "https://www.youtube.com/embed/scQ2t5plRxc?si=g-Jzf9s36lrmiFl5",
  },
  {
    id: 4,
    title: "Fin Statement Parser",
    description: "Upload any financial statement and have it parsed into structured JSON for easy analysis. (Jul 2024)",
    videoUrl: "https://www.youtube.com/embed/-rXg6l3HFqw",
  },
  {
    id: 3,
    title: "Research Dashboard",
    description: "Upload any research paper, select difficulty, and interact with an AI-generated dashboard for faster comprehension. (Jul 2024)",
    videoUrl: "https://www.youtube.com/embed/PIHz67G4rzg?si=JpVuNZ96ObOF9MFZ",
  },
  {
    id: 6,
    title: "Teddy: AI Short Film",
    description: "A short film created using Runway Gen 3 Alpha, showcasing the creative potential of AI in filmmaking. (Jul 2024)",
    videoUrl: "https://www.youtube.com/embed/3c1NI9_CuoI",
  },
  {
    id: 5,
    title: "EmojiFactory.io",
    description: "Create and share custom emojis with ease. (Jul 2024)",
    link: "https://emojifactory.io",
    coverImage: "/cover.png",
  },
];

const ProjectShowcase: React.FC = () => {
  const [width, setWidth] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();
  const [isMobile, setIsMobile] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [columns, setColumns] = useState(3);

  const CARD_WIDTH = 320;
  const CARD_GAP = 24;

  useEffect(() => {
    const handleResize = () => {
      if (carouselRef.current && containerRef.current) {
        setWidth(carouselRef.current.scrollWidth - carouselRef.current.offsetWidth);
        const containerWidth = containerRef.current.offsetWidth;
        const newColumns = Math.max(1, Math.floor((containerWidth + CARD_GAP) / (CARD_WIDTH + CARD_GAP)));
        setColumns(newColumns);
      }
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let animationFrameId: number;

    const animate = () => {
      if (!isExpanded && carouselRef.current) {
        const now = Date.now() / 1000; // current time in seconds
        const x = -width * (now % 30) / 30; // Complete rotation every 30 seconds
        controls.set({ x });
      }
      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [controls, width, isExpanded]);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
    if (!isExpanded) {
      controls.set({ x: 0 });
    }
  };

  return (
    <section id="projects" className="py-6 bg-cream overflow-hidden">
      <div className="max-w-5xl mx-auto px-4" ref={containerRef}>
      <h2 className="font-serif text-3xl text-gray-800 mb-2">
          A few LLM Projects
          <p className="font-sans text-sm text-gray-600 mt-2 italic">
            *These are some old personal projects built with Claude Sonnet 3.5, excited to share systems I am building with o series models soon
          </p>
          <div className="w-full h-0.5 bg-gray-300 mt-2"></div>
        </h2>
        <div className={`transition-all duration-500 ease-in-out ${isExpanded ? 'h-auto' : 'h-[380px] overflow-hidden'}`}>
          <motion.div 
            ref={carouselRef}
            className={`${isExpanded ? 'flex justify-center' : 'cursor-grab'}`}
            layout
            transition={{ duration: 0.5 }}
          >
            <motion.div
              animate={controls}
              className={isExpanded ? `grid grid-cols-1 sm:grid-cols-2 md:grid-cols-${columns} gap-6 justify-items-center` : 'flex space-x-6'}
              style={isExpanded ? { maxWidth: `${columns * (CARD_WIDTH + CARD_GAP) - CARD_GAP}px` } : {}}
            >
              {projects.map((project) => (
                <ProjectCard 
                  key={project.id}
                  project={project}
                  isMobile={isMobile}
                  isExpanded={isExpanded}
                />
              ))}
            </motion.div>
          </motion.div>
        </div>
        <div className="text-center mt-2 mb-2">
          <button
            onClick={toggleExpand}
            className="bg-cream border-2 border-gray-400 text-gray-800 px-6 py-2 rounded-full hover:bg-gray-100 transition-colors duration-300 font-serif text-sm shadow-md hover:shadow-lg"
          >
            {isExpanded ? 'Collapse' : 'See All'}
          </button>
        </div>
      </div>
    </section>
  );
};

const ProjectCard: React.FC<{ 
  project: Project, 
  isMobile: boolean,
  isExpanded: boolean
}> = ({ project, isMobile, isExpanded }) => {
  return (
    <motion.div 
      className={`bg-cream border-2 border-gray-300 rounded-lg overflow-hidden w-80 shadow-md hover:shadow-xl transition-all duration-300 flex-shrink-0`}
      whileHover={{ 
        scale: isExpanded ? 1 : 1.03, 
        transition: { duration: 0.2 },
      }}
      layout
    >
      <div className="p-4 flex flex-col h-full">
        <h3 className="font-serif text-xl text-gray-800 mb-2">{project.title}</h3>
        <div className="w-full h-0.5 bg-gray-300 mb-4"></div>
        <p className="text-gray-700 mb-4 text-sm flex-grow overflow-hidden">{project.description}</p>
        {project.videoUrl ? (
          <div className="aspect-w-16 aspect-h-9 mt-auto">
            <iframe
              src={project.videoUrl}
              title={project.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-full rounded-lg"
            ></iframe>
          </div>
        ) : project.link ? (
          <div className="aspect-w-16 aspect-h-9 mt-auto bg-white border border-gray-200 rounded-lg overflow-hidden">
            <a 
              href={project.link} 
              target="_blank" 
              rel="noopener noreferrer"
              className="block w-full h-full"
            >
              <div className="p-4 flex flex-col h-full">
                <div className="flex-grow flex items-center justify-center">
                  <img 
                    src={project.coverImage || "/api/placeholder/300/150"}
                    alt={`${project.title} preview`} 
                    className="max-w-full max-h-full object-contain"
                  />
                </div>
                <div className="mt-2 text-sm text-gray-600 truncate">{project.link}</div>
              </div>
            </a>
          </div>
        ) : null}
      </div>
    </motion.div>
  );
};

export default ProjectShowcase;