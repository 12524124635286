import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

interface ExcitementItem {
  text: string;
  emoji: string;
}

interface ExcitementSection {
  title: string;
  items: ExcitementItem[];
}

const excitementData: ExcitementSection[] = [
  {
    title: "In the next year",
    items: [
      { text: "Real AI Agents", emoji: "🤖" },
      { text: "Full o4", emoji: "🧠" },
      { text: "Move into a new house", emoji: "🏡" },
    ],
  },
  {
    title: "Next few years",
    items: [
      { text: "Meet new people", emoji: "🤝" },
      { text: "Build things not previously possible", emoji: "👀" },
      { text: "Acceleration of AI progress", emoji: "🏗️" },
    ],
  },
  {
    title: "Long term",
    items: [
      { text: "AI providing equal access to highest level education", emoji: "🎓" },
      { text: "Become a scratch golfer", emoji: "⛳" },
      { text: "Start a company with my friends", emoji: "🚀" },
    ],
  },
];

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const ExcitedFor: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  return (
    <section className="py-12 bg-cream overflow-hidden" id="excited-for">
      <div className="max-w-5xl mx-auto px-4">
        <h2 className="font-serif text-3xl text-gray-800 mb-8">
          What I'm Excited For
          <div className="w-full h-0.5 bg-gray-300 mt-2"></div>
        </h2>
        <div 
          className={`
            ${isMobile ? 'flex flex-nowrap overflow-x-auto snap-x snap-mandatory' : 'grid grid-cols-1 md:grid-cols-3'}
            gap-6 pb-4
          `}
        >
          {excitementData.map((section, index) => (
            <ExcitementSection key={index} section={section} isMobile={isMobile} />
          ))}
        </div>
      </div>
    </section>
  );
};

const ExcitementSection: React.FC<{ section: ExcitementSection; isMobile: boolean }> = ({ section, isMobile }) => {
  return (
    <div className={`
      space-y-4 
      ${isMobile ? 'flex-shrink-0 w-80 snap-center' : ''}
    `}>
      <h3 className="font-serif text-xl text-gray-800 mb-4">{section.title}</h3>
      <div className="space-y-3">
        {section.items.map((item, index) => (
          <ExcitementCard key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

const ExcitementCard: React.FC<{ item: ExcitementItem }> = ({ item }) => {
  return (
    <motion.div 
      className="bg-cream border-2 border-gray-300 rounded-lg p-3 flex items-center space-x-3"
      whileHover={{ scale: 1.02, boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)" }}
      transition={{ type: "spring", stiffness: 300, damping: 20 }}
    >
      <span className="text-2xl" role="img" aria-label={item.text}>
        {item.emoji}
      </span>
      <p className="text-sm text-gray-700 font-serif">{item.text}</p>
    </motion.div>
  );
};

export default ExcitedFor;